<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page_header_new";
import appConfig from "@/app.config";
import axios from "axios";
import {mapState} from "vuex";
import {loggedInfoObject} from "@/state/helpers";

/**
 * Starter-page component
 */
export default {
  page: {
    title: "템플릿 관리",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      title: "템플릿 관리",
      items: "전송될 템플릿을 미리 볼 수 있습니다.",
      templateList: [],

    };
  },
  computed: {
    ...mapState(loggedInfoObject),
  },
  components: {
    Layout,
    PageHeader,
  },
  mounted() {
    this.callTemplete()
  },
  methods: {
    //템플릿 가져오기
    callTemplete() {
      let url = `/api/kakao/template/${this.logged_info.mart_srl}`

      axios.get(url).then((res) => {
        //console.log(res.data)
        this.templateList = res.data
      }).catch(err => console.log(err))
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="card" style="background-color: #b2c7da">
      <div class="card-body">
        <div class="style-alram-talk"
             style="width: 300px;"
             v-for="row in templateList"
             :key="row.alarmtalk_temp_srl"
        >
          <p class="title m-0" style="padding: 1rem; background-color: #ffeb34">
            {{ row.alarmtalk_temp_srl }}번 템플릿
            <img :src="require('@/assets/mcimages/img_kakao.png')" alt="kakao">
          </p>

          <div style="height:auto;">
            <div
                style="width:100%; margin-top:0; background-color: white; border-bottom-left-radius: 16px; border-bottom-right-radius: 16px;">
              <p class="m-0" style="padding: 1rem;" v-html="row.template.replaceAll('\n', '<br />')"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
.style-alram-talk {
  display: inline-block;
  border-radius: 16px !important;
  margin-right: 20px;
  vertical-align: top;
  margin-bottom: 20px;
}

.style-alram-talk p.title {
  background: #ffeb34 !important;
  position: relative;
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
}

.style-alram-talk p.title img {
  position: absolute;
  top: -10px;
  right: -10px;
  width: 34px;
}
</style>
